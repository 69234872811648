import axios from "axios";
import socketMixin from "@/socket-mixin.js";
import permissionMixin from "@/permissions-mixin.js";
import socket from "@/socket.js";
export default {
  mixins: [socketMixin, permissionMixin],
  components: {
    "create-job-modal": () =>
      import("@/components/modals/create-job-modal.vue"),
    "list-jobs-modal": () => import("@/components/modals/list-jobs-modal.vue"),
    "job-details": () => import("@/components/modals/job-details.vue"),
    "bulk-assign-modal": () =>
      import("@/components/modals/bulk-assign-modal.vue"),
  },
  data() {
    return {
      currentWarehouse: {},
      showDatesModal: false,
      currentMaterial: {},
      currentDates: {},
      current_modal_dates: {},
      show_bulk_assign_modal: false,
    };
  },
  methods: {
    addJobMaterial() {
      var fount = false;
      let amount = 0;
      var fount_at = -1;
      this.$parent.masterJobMaterials.forEach((r, idx) => {
        if (
          r.material.IdST == this.currentMaterial.IdST &&
          this.currentWarehouse.Id == r.warehouse.Id
        ) {
          fount = true;
          fount_at = idx;
        }
      });
      if (!fount) {
        var item = {
          material: { ...this.currentMaterial },
          job: localStorage.currentEvent,
          warehouse: this.currentWarehouse,
          dates: [],
        };

        // set status as added = 3 -> Items Left
        this.currentDates.added = 1; //this.currentDates.QtyWas - this.currentDates.Qty > 0 ? 3 : 1
        this.currentDates.hash = this.getHash();
        this.currentDates.warehouse = this.currentWarehouse;

        this.$parent.jobs = [
          ...this.$parent.jobs.map((j) => {
            if (j.Caption.toLowerCase().includes("- request")) {
              j.materials = [
                ...j.materials.map((m) => {
                  m.date_amount = [
                    ...m.date_amount.map((da) => {
                      if (da.m_id == this.currentDates.m_id) {
                        da.added = 1;
                        da.hash = this.currentDates.hash;
                        da.warehouse = this.currentWarehouse;
                        da.checked = null;
                      }
                      return da;
                    }),
                  ];
                  return m;
                }),
              ];
            }
            return j;
          }),
        ];

        // this.currentDates.remaining = this.currentDates.QtyWas - this.currentDates.Qty

        item.dates.push({ ...this.currentDates });
        var newdata = JSON.parse(JSON.stringify(item));
        delete newdata.material.date_amount;
        this.$parent.masterJobMaterials.push(newdata);
        //the operator has changed the amount for one of the date.
        //The materials date needs to be splited in two lines
        if (this.currentDates.Qty != this.currentDates.QtyWas) {
          //1.2 add to local materials dates array this change
          amount = this.currentDates.QtyWas - this.currentDates.Qty;
          this.currentMaterial.date_amount.push({
            ...this.currentDates,
            Qty: amount,
            QtyWas: amount,
            hash: this.getHash(),
            added: 0,
            m_id: null,
            m_guid: null,
          });
          //1.1 send this change to the server
          // console.log('save to tetris ', this.currentDates, this.currentMaterial, this.$parent.jobs_requests[0])
          this.addMaterialDateRequest(
            this.currentMaterial,
            this.$parent.jobs_requests[0],
            0
          );
        }
      } else {
        var date_fount_at = -1;
        this.currentDates.warehouse = this.currentWarehouse;
        let m_hash = "";
        this.$parent.masterJobMaterials[fount_at].dates = [
          ...this.$parent.masterJobMaterials[fount_at].dates.map(
            (date, idx) => {
              if (
                date.IdST == this.currentDates.IdST &&
                date.warehouse.Id == this.currentDates.warehouse.Id &&
                this.$moment(date.dates[0]).format("YYYY-MM-DD") ==
                  this.$moment(this.currentDates.dates[0]).format(
                    "YYYY-MM-DD"
                  ) &&
                this.$moment(date.dates[1]).format("YYYY-MM-DD") ==
                  this.$moment(this.currentDates.dates[1]).format("YYYY-MM-DD")
              ) {
                date_fount_at = idx;
                date.Qty = date.Qty + this.currentDates.Qty;
                // this.currentDates.hash
                m_hash = date.hash;
              }
              return date;
            }
          ),
        ];
        this.$parent.jobs = [
          ...this.$parent.jobs.map((j) => {
            if (j.Caption.toLowerCase().includes("- request")) {
              j.materials = [
                ...j.materials.map((m) => {
                  m.date_amount = [
                    ...m.date_amount.map((da) => {
                      if (da.m_id == this.currentDates.m_id) {
                        da.added = 1;
                        da.hash = m_hash; //this.currentDates.hash
                        da.warehouse = this.currentWarehouse;
                        da.checked = null;
                      }
                      return da;
                    }),
                  ];
                  return m;
                }),
              ];
            }
            return j;
          }),
        ];

        this.currentDates.added = 1;

        if (date_fount_at == -1) {
          this.currentDates.remaining =
            this.currentDates.QtyWas - this.currentDates.Qty;
          this.currentDates.hash = this.getHash();

          this.$parent.masterJobMaterials[fount_at].dates.push({
            ...this.currentDates,
          });
        }
      }
      this.$toast(
        "<br>[ " +
          this.currentDates.Qty +
          " x ] <br>" +
          this.currentMaterial.Caption +
          (this.currentDates.Qty > 1 ? " were" : " was") +
          " added  to the pending job's list" +
          "<br> of " +
          this.currentWarehouse.Caption +
          "<br><br>"
      );
      // this.showDatesModal = false
    },
    getHash() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    totalMaterials(m) {
      var t = 0;
      if (m.date_amount)
        m.date_amount.forEach((da) => {
          t += parseFloat(da.Qty);
        });
      return t;
    },
    totalJobsMaterial(m) {
      var total = 0;
      m.dates.forEach((d) => {
        total += parseFloat(d.Qty);
      });
      return total;
    },
    setJobMaterial(material) {
      this.currentMaterial = material;
      this.showDatesModal = true;
    },
    getMaterialStock(d) {
      console.log("d...", d);
      this.current_modal_dates = [];
      this.currentDates = [];
      setTimeout(() => {
        this.current_modal_dates = d;
        this.currentDates = d;
      }, 400);

      // this.showDatesModal = true
      // this.$parent.materialsAvailability = []
      // this.$parent.getItemAvailability(this.currentMaterial, d, this.currentWarehouse)
    },
    deleteDate(d, m) {
      console.log("delete", m);
      this.$parent.masterJobMaterials = this.$parent.masterJobMaterials.filter(
        (m) => {
          m.dates = m.dates.filter((sd) => sd.hash != d.hash);
          if (m.dates.length > 0) return m;
        }
      );
      this.$parent.jobs = this.$parent.jobs.map((j) => {
        j.materials = j.materials.filter((material) => {
          material.date_amount = material.date_amount.map((date) => {
            if (date.hash == d.hash) {
              date.hash = "";
              date.added = 0;
            }
            return date;
          });
          return material;
        });
        return j;
      });
      console.log("deleting", d, m, this.$parent.jobs);
    },
    change_select_warehouse(event) {
      var wid = event.target.value;
      this.$parent.warehouses.map((pw) => {
        if (pw.Id == wid) this.currentWarehouse = pw;
      });
      console.log("currentWarehouse", this.currentWarehouse);
    },
    has_warehouse_jobs(w) {
      let has = false;
      this.$parent.masterJobMaterials.map((m) => {
        if (w == m.warehouse.Id) {
          has = true;
        }
      });
      return has;
    },
    getMaterialData(m) {
      let mat = {};
      this.$parent.materials_data.forEach((material) => {
        if (material.IdStockType == m.IdST) mat = material;
      });
      return mat;
    },
    async createJobs() {
      let job_list = [];
      // if(confirm("Are you sure you want to save the jobs?"))
      this.$fire({
        // title: 'Are you sure?',
        text: this.$t("save_jobs_warehouse"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then(async (response) => {
        if (response.value) {
          console.log("all jobs", this.all_jobs);
          let has_invalid_dates = 0;

          // easyjob won't save the jobs if the start date is in the past
          // so we block this operation if one of these jobs is in the past

          let today = new Date().setHours(0, 0, 0, 0);

          this.all_jobs.forEach((w) => {
            w.jobs.forEach((j) => {
              has_invalid_dates += this.$moment(j.starts).isSameOrAfter(today)
                ? 0
                : 1;
            });
          });

          if (has_invalid_dates > 0)
            alert(
              this.$t("job_can_not_be_saved"),
              {
                has_invalid_dates: has_invalid_dates,
              } /*"There are " + has_invalid_dates + " jobs that cannot be saved!" */
            );

          if (has_invalid_dates == 0)
            // this.all_jobs.forEach(async(wj, idx) => {
            // for(var idx = 0; idx < this.all_jobs.length; idx++){
            for (const wj of this.all_jobs) {
              /// const wj = this.all_jobs[idx]
              // wj.jobs.forEach(async(j,index) => {
              // for(var index = 0; index < wj.jobs.length; index++){
              for (const j of wj.jobs) {
                // const j = wj.jobs[index]
                // console.log('jooob', j)
                // console.log(j.starts, j.ends)

                // the timezone offset is the one the logged in user has
                // let user = JSON.parse(localStorage.tetris_user)

                let starts = j.starts; //this.$moment(j.starts)
                let ends = j.ends; // this.$moment(j.ends)
                // let ends = j.ends.replace('Z', '')
                console.log(starts, ends, j.starts, j.ends);

                let job = {
                  Caption:
                    this.$parent.event_details.Caption +
                    " - w: " +
                    wj.warehouse_object.Caption,
                  Address_Delivery: null,
                  Contact_Delivery: null,
                  DayTimeOut: starts,
                  DayTimeIn: ends,
                  IdJob: null,
                  JobState: {
                    ID: 6, // TODO: status of job!
                    IdJobState: 6, // same here
                  },
                  Project: {
                    ID: this.$parent.currentEvent,
                    IdProject: this.$parent.currentEvent,
                  },
                  Stock: {
                    ID: wj.warehouse,
                    IdStock: wj.warehouse,
                  },
                };
                let last_call = false;

                // setTimeout(async() => {
                let aa = await this.createSingleJob(
                  job,
                  j,
                  this.$parent,
                  this.$tetris_server,
                  last_call
                );
                console.log(aa);

                //     if(idx == this.all_jobs.length -1) {
                //     console.log('last call', index)
                //     this.$toast(this.$t("toaster_job_successful_warehouse"))

                // }
                // TODO: this will fail if the dates are in the past!
                // }, idx == 0 ? 0 : 500 *  Math.floor(Math.random() * 10))
                job_list.push(job);
              } //)
            } //)
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });

      // console.log(job_list)
    },
    async createSingleJob(job, j, $parent, $tetris_server, last_call) {
      $parent.general_toaster = "[ ########## CREATING " + job.Caption + "]";
      var assqqqq = await $parent
        .postEJDatas("jobs/save/", job)
        .then(async (response) => {
          if (response.data.Success == true) {
            let new_job = response.data.ID;

            $parent.general_toaster =
              "[EASY JOB RESPONSE] --------------------- " +
              response.data.Message;
            $parent.general_toaster =
              "(Saving " +
              job.Caption +
              ") ------------------- getting bill of items...";
            var aal = await $parent
              .getEJDatas("items/billofitems/" + new_job)
              .then((job_details) => {
                // console.log(job_details, j)
                let IdST2JG = job_details.data.IdST2JG;
                let list_of_materials = [];
                let list_of_tetris_materials = [];
                j.materials.forEach((material, idx) => {
                  let qty = material.Qty;
                  let stockType = material.IdST;
                  let linkedStockType = material.IdST;
                  if (material.linked) {
                    qty = Math.ceil(
                      parseFloat(material.Qty) /
                        parseFloat(material.linked.factor)
                    );
                    stockType = material.linked.material_stocktype;
                    linkedStockType = material.linked.material_stocktype;
                  }
                  let new_material = {
                    IdJob: new_job,
                    IdStockType: stockType,
                    IdStockType2JobGroup: IdST2JG,
                    /*IdStockType2JobType: 1,*/
                    Quantity: qty,
                  };
                  list_of_materials.push(new_material);
                  list_of_tetris_materials.push({
                    ...new_material,
                    material: material.m_id,
                    linked_stocktype: linkedStockType,
                    linked: material.linked,
                    job: new_job,
                    idx: idx,
                    DayTimeOut: job.DayTimeOut,
                    DayTimeIn: job.DayTimeIn,
                    project_id: job.Project.IdProject,
                  });
                });

                console.log(aal, list_of_materials);
                $parent.general_toaster =
                  "(Saving " + job.Caption + ") ------------------ materials";
                var eee = $parent
                  .postEJDatas("custom/batchbook/", list_of_materials)
                  .then(async (new_material_request) => {
                    let message =
                      response.data.Message == null
                        ? "Success"
                        : response.data.Message;
                    $parent.general_toaster =
                      "[EASY JOB RESPONSE] ----------------------- " + message;
                    // store here the job_id to the tetris material_id
                    console.log("list", list_of_tetris_materials);
                    const aaa = await axios
                      .post($tetris_server + "/set/job/material", {
                        materials: list_of_tetris_materials,
                      })
                      .then((material_date_job) => {
                        console.log(material_date_job);
                        console.log(last_call);
                      });
                    console.log([aaa, new_material_request]);
                  });
                console.log(eee);
              });
          } else
            $parent.general_toaster =
              "############## ERROR: " + response.data.Message;
        });
      console.log(assqqqq);
      // this was made because there's a throttle which returns 500 error responses... if the requests
      // are done within the same moment... so a random timeout makes them consecutive
    },
    getWarehouse(w) {
      let warehouse = "";
      if (this.$parent.event_details)
        this.$parent.event_details.Jobs.map((e) => {
          if (e.IdJob == w)
            warehouse =
              e.Stock.Caption +
              '<span class="is-pulled-right" style="color: #f1f1f1">[' +
              e.Number +
              "]</span>";
        });
      return warehouse;
    },
    getWarehouseNr(w) {
      let warehouse = "";
      if (this.$parent.event_details)
        this.$parent.event_details.Jobs.map((e) => {
          if (e.IdJob == w) warehouse = e.Number;
        });
      return warehouse;
    },
    getWarehouseCaption(w) {
      let warehouse = "";
      if (this.$parent.event_details)
        this.$parent.event_details.Jobs.map((e) => {
          if (e.IdJob == w) warehouse = e.Stock.Caption;
        });
      return warehouse;
    },
    getWarehouse_block(w) {
      let warehouse = "";
      if (this.$parent.event_details)
        this.$parent.event_details.Jobs.map((e) => {
          if (e.IdJob == w)
            warehouse =
              '<span class="has-text-dark is-pulled-left">[' +
              e.Number +
              "]</span>&nbsp;" +
              '<i class="has-text-grey is-pulled-right">' +
              e.Stock.Caption +
              "</i><br>";
        });
      return warehouse;
    },
    warehouse_jobs_list(w) {
      return this.$parent.jobs_warehouses.filter((j) => j.Stock.ID == w.ID);

      // console.log(this.$parent.jobs_requests)
      // console.log(w)

      // return this.$parent.jobs_requests[0].materials.map(jr => {
      // })

      // console.log('this.parent.jobs_requests', this.$parent.jobs_requests)
      // console.log('w', w)
    },
    addTetrisMaterial(post_data, mat, reload_project) {
      console.log("post data", post_data, mat, this.$parent.jobs_requests[0]);
      axios
        .post(this.$tetris_server + "/add/job/material", {
          material: post_data,
          list: mat.date_amount.map((mm) => {
            return {
              Qty: mm.Qty,
              dates: mm.dates_original,
              warehouse_job: mm.warehouse_job,
              m_guid: mm.m_guid,
            };
          }),
          project: this.$parent.event_details,
          job: {
            ID: this.$parent.jobs_requests[0].ID,
            Caption: this.$parent.jobs_requests[0].Caption,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          if (this.job_creation_ended)
            setTimeout(() => {
              // window.location.reload()
              if (reload_project) this.$parent.getProject(reload_project);
              else {
                if (resp.data.length > 0) {
                  // we have new materials to update
                  resp.data.map((m) => {
                    this.$parent.jobs = [
                      ...this.$parent.jobs.map((j) => {
                        if (j.ID == m.job_id) {
                          j.materials = [
                            ...j.materials.map((maj) => {
                              if (maj.IdST == m.material_id) {
                                maj.m_id = m.id;
                                maj.m_guid = m.guid;
                              }
                              return maj;
                            }),
                          ];
                        }
                        return j;
                      }),
                    ];
                    this.currentMaterial.date_amount = [
                      ...this.currentMaterial.date_amount.map((dates) => {
                        if (dates.Qty == m.amount && dates.m_id == null) {
                          dates.m_id = m.id;
                          dates.m_guid = m.guid;
                        }
                        return dates;
                      }),
                    ];
                  });
                }
              }

              this.$toast(
                this.$t("material_save_toaster_warehouse", { mat: mat.Caption })
              );
            }, 500);
        });
    },
    addMaterialDateRequest(material, jb, add = 1) {
      let m = { ...material };
      this.$parent.jobs.forEach((j) => {
        if (j.ID == jb.ID && j.Caption.toLowerCase().includes("- request")) {
          // only the requests
          j.materials.forEach((mat) => {
            this.job_creation_ended = false;
            if (mat.IdST == m.IdST) {
              // console.log(m)
              var post_data = {
                IdJob: j.ID,
                IdStockType: m.IdST,
                IdStockType2JobGroup: m.IdST2JG,
                IdStockType2JobType: m.IdST2JT,
                Quantity: 0,
              };
              /// a new date will be added to the tetris materials list with a new date
              if (add == 1) {
                m.dates = [
                  new Date(this.$parent.event_details.StartDate),
                  new Date(this.$parent.event_details.EndDate),
                ];
                // add by default 1 qty for the new date
                let default_qty = 1;
                if (this.totalMaterials(mat) + default_qty > mat.Qty)
                  post_data.Quantity =
                    this.totalMaterials(mat) + default_qty - mat.Qty;

                if (post_data.Quantity >= 0)
                  axios
                    .post(this.$ej_server + "/api.json/Items/Book", post_data, {
                      headers: this.$ej_headers,
                    })
                    .then((r) => {
                      console.log("quantity 0 or bigger", r.data.Message);
                      if (add == 1) {
                        let new_mat = {
                          Qty: default_qty,
                          QtyWas: default_qty,
                          dates: m.dates,
                          date_was: m.date_was,
                          warehouse_job: m.warehouse_job,
                          m_id: m.m_id,
                          m_guid: null,
                        };
                        mat.date_amount.push({ ...new_mat });
                      }
                      let reload = 0; // if after the add it will reload the materials
                      this.addTetrisMaterial(post_data, mat, reload);
                      console.log(post_data);
                    });
              } else {
                if (this.totalMaterials(mat) > mat.Qty) {
                  post_data.Quantity = this.totalMaterials(mat) - mat.Qty;
                  console.log("editing", post_data.Quantity);
                  axios
                    .post(this.$ej_server + "/api.json/Items/Book", post_data, {
                      headers: this.$ej_headers,
                    })
                    .then((r) => {
                      console.log("message", r.data.Message);
                      // Add to tetris
                      this.addTetrisMaterial(post_data, mat);
                    });
                }
                // 2. else easy job doesn't need to be updated just tetris
                else this.addTetrisMaterial(post_data, mat);
                console.log("tetris update");
              }
            }
            this.job_creation_ended = true;
          });
        }
      });
    },
    delete_warehouse_jobs(jid) {
      this.$fire({
        text: this.$t("are_you_sure"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          axios
            .post(this.$tetris_server + "/delete/warehouse/jobs", {
              warehouse_job: jid,
            })
            .then((res) => {
              axios
                .post(
                  this.$ej_server + "/api.json/jobs/delete/" + jid,
                  {},
                  { headers: this.$ej_headers }
                )
                .then(() => {
                  if (res.data == 0) {
                    // && r.data == {} ???
                    console.log("res data", res.data);
                    // window.location.reload()
                    // console.log('res', res)
                    // remove from view without refresh
                    // console.log('delete job', jid)
                    // console.log('this.$parent.jobs_warehouses', this.$parent.jobs_warehouses)
                    // this.$parent.jobs_warehouses = this.$parent.jobs_warehouses.filter(j => j.IdJob != jid)
                  }
                  this.$toast(this.$t("job_deleted_successfully"));
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                });
            });
        }
      });
    },
    socket_listener(data) {
      if (data.by) {
        let operator =
          '<p class = "has-text-primary-dark">' + " " + data.by.name + "</p>";
        if (data.type == "add note") {
          this.$toast(
            "<div>" +
              "A new note has been added by" +
              operator +
              " " +
              "</div>",
            "info"
          );
        } else if (data.type == "delete note") {
          this.$toast(
            "<div>" + "A note has been deleted by" + operator + " " + "</div>",
            "info"
          );
        } else if (data.type == "edit note") {
          this.$toast(
            "<div>" + "A note has been edited by" + operator + " " + "</div>",
            "info"
          );
        }
      }
    },
    toggle_bulk_modal() {
      this.show_bulk_assign_modal = !this.show_bulk_assign_modal;
    },
  },
  computed: {
    availability() {
      return this.$parent.materialsAvailability;
    },
    total_jobs() {
      var total = 0;
      this.all_jobs.map((w) => {
        total += w.jobs.length;
      });
      return total;
    },
    total_warehouses() {
      var warehouses = [];
      this.$parent.masterJobMaterials.map((m) => {
        if (!warehouses.includes(m.warehouse.Id)) {
          warehouses.push(m.warehouse.Id);
        }
      });
      return warehouses.length;
    },
    all_jobs() {
      var warehouse_jobs = [];
      this.$parent.masterJobMaterials.map((m) => {
        let exists = warehouse_jobs.filter(
          (w) => w.warehouse == m.warehouse.Id
        );
        // if the warehouse is not in the jobs list
        if (exists.length == 0) {
          warehouse_jobs.push({
            parent_job: m.job,
            warehouse: m.warehouse.Id,
            warehouse_object: m.warehouse,
            // id: m.m_id,
            jobs: [],
            hash: m.hash,
          });
          // console.log('wareh job material', exists)
        }
        // the warehouse is in the jobs list
        // access dates
        m.dates.map((d) => {
          warehouse_jobs = warehouse_jobs.map((w) => {
            if (w.warehouse == m.warehouse.Id) {
              // must check first if the job dates already exist
              let date = w.jobs.filter((dd) => {
                // console.log('material dates' , dd )

                // IMPORTANT: This should group based only on the date part and not the datetime...
                //						otherwise this will group jobs based on the time part which is not correct

                return (
                  this.$moment(dd.starts).format("YYYY-MM-DD") ==
                    this.$moment(d.dates[0]).format("YYYY-MM-DD") &&
                  this.$moment(dd.ends).format("YYYY-MM-DD") ==
                    this.$moment(d.dates[1]).format("YYYY-MM-DD")
                );
                // return new Date(dd.starts).getTime() == new Date(d.dates[0]).getTime() && new Date(dd.ends).getTime() == new Date(d.dates[1]).getTime()
              });
              // console.log('ddd', date)
              // add to jobs list if dates don't exist
              if (date.length == 0) {
                console.log("dddddates", d.dates_original);
                w.jobs.push({
                  starts: d.dates_original[0],
                  ends: d.dates_original[1],
                  materials: [{ ...m.material, Qty: d.Qty, m_id: d.m_id }],
                  hash: d.hash,
                });
              }
              // else add to that job the material
              else {
                w.jobs = w.jobs.map((job) => {
                  if (
                    new Date(job.starts).getTime() ==
                      new Date(d.dates_original[0]).getTime() &&
                    new Date(job.ends).getTime() ==
                      new Date(d.dates_original[1]).getTime()
                  ) {
                    job.materials.push({
                      ...m.material,
                      Qty: d.Qty,
                      m_id: d.m_id,
                    });
                  }
                  return job;
                });
              }
            }
            return w;
          });
        });
      });
      return warehouse_jobs;
    },
    warehouses() {
      var l = {};
      this.$parent.jobs_warehouses.forEach((j) => {
        if (!l[j.Stock.ID]) {
          l[j.Stock.ID] = {};
        }
        l[j.Stock.ID] = j.Stock;
      });
      return l;
    },
    checked_dates_amount() {
      var ch = [];
      this.$parent.jobs_requests.map((j) => {
        j.materials.map((m) => {
          m.date_amount.map((d) => {
            if (d.checked) {
              console.log("found", d, m, j);
              ch.push({
                date: {
                  ...d,
                  material_IdST: m.IdST,
                  material_Number: m.Number,
                  material_Caption: m.Caption,
                  material_Category: m.Category,
                  job_IdJob: j.IdJob,
                  job_Number: j.nmumber,
                },
                material: m,
              });
            }
          });
        });
      });
      return ch;
    },
  },
  created() {
    if (this.am_allowed("warehouse.can_read")) {
      // Check if have permissions can_see
      this.$document.title = "Logistic: " + this.$parent.event_details.Caption;
      if (this.$route.params.id && this.$parent.event_jobs.length == 0) {
        this.$parent.event_jobs = [];
        this.$parent.event_details.Jobs = [];
        this.$parent.currentEvent = this.$route.params.id;
        this.$parent.getProject();
      }
      this.$parent.app_lang = this.$i18n.locale;
      this.$parent.app_route = this.$parent.get_url();
    } else {
      if (this.am_allowed("request.can_read")) {
        this.$router.push("/request-accept/" + this.$route.params.id);
      } else if (this.am_allowed("warehouse.can_read")) {
        this.$router.push("/request-accept/" + this.$route.params.id);
      } else if (this.am_allowed("parameter.can_read")) {
        this.$router.push("/delivery-parameters/" + this.$route.params.id);
      } else if (this.am_allowed("planning.can_read")) {
        this.$router.push("/delivery-plan/" + this.$route.params.id);
      } else {
        this.$router.push("/");
      }
    }
  },
  mounted() {
    console.log("mounted");
    socket.on("someone said", this.socket_listener);
  },
  beforeDestroy() {
    socket.off("someone said", this.socket_listener);
  },
};
